body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
}

.popup {
    height: 85px;
    width: 200px;
    text-align: center;
}

.popupTitle {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    margin: 0;
}

.popupInfo {
    color: #008060;
    font-weight: 800;
    font-size: 12px;
    line-height: 10px;
    margin-top: 0;
    margin-bottom: 6px;
}

.popupButton {
    background: #000000;
    color: white;
    border-radius: 50px;
    font-size: 10px;
    font-weight: bold;
    border-style: none;
    width: 120px;
    height: 25px;
}

.popupButton:hover {
    cursor: pointer;
}

.infoCircleContainer {
    margin: auto;
    width: 100%;
    text-align: center;
}
.infoContainer {
    z-index: 1;
    display: flex;
    justify-content: center;
}

.infoPanel {
    z-index: 1;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 450px;
    background-color: white;
    color: black;
    text-align: center;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    height: 100px;
}

.infoCircle {
    z-index: 2;
    position: relative;
    background-color: black;
    height: 125px;
    width: 125px;
    border-radius: 50%;
    bottom: 65px;
    margin: 0 auto;
}

.infoBar {
    z-index: 4;
    position: relative;
    background: #ffffff;
    border: 3px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    margin: 0 auto;
    width: 135px;
    height: 70px;
    bottom: 190px;
}

.info {
    z-index: 6;
    position: relative;
    color: white;
    margin: 0 auto;
    font-size: 15px;
    bottom: 175px;
    font-weight: bold;
    width: 60px;
}

.infoDistance {
    float: right;
    padding-right: 20px;
    font-weight: bold;
    font-size: 30px;
    color: #008060;
    line-height: 1.6;
    margin: 0;
}

.infoText {
    float: left;
    padding-left: 20px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 0.7;
}

.nearestCansContainer {
    display: inline-flexbox;
}

.number {
    font-size: 30px !important;
}

.nav {
    z-index: 2;
    position: fixed;
    width: 100%;
    height: 50px;
    background: white;
}

.navInfoTitle {
    z-index: -1;
    display: inline-block;
    margin-right: auto;
    padding: 13px;
    width: 197px;
    height: 32px;
    cursor: pointer;
}

.navInfoImage {
    z-index: 10;
    margin-left: auto;
    float: right;
    padding: 13px;
    height: 50%;
    cursor: pointer;
}

.infoBarTest {
    position: absolute;
    width: 115px;
    height: 70px;
    left: 18px;
    bottom: 13px;

    background: #ffffff;
    border: 3px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
}

.infoBarTestTwo {
    position: absolute;
    width: 115px;
    height: 70px;
    right: 18px;
    bottom: 13px;

    background: #ffffff;
    border: 3px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
}

.trashIcon {
    position: absolute;
    height: 40px;
    width: 32px;
    bottom: 15px;
    left: 21px;
}

.recycleIcon {
    position: absolute;
    height: 50px;
    width: 41px;
    bottom: 6px;
    left: 18px;
}

.nearbyTrash {
    position: absolute;
    font-size: 30px;
    font-weight: 700;
    right: 21px;
    line-height: 1px;
}

.personalLinks {
    text-align: center;
}

.customLink {
    color: #008060 !important;
    font-weight: 800;
    text-decoration: none;
}

.splash-cover {
    position: relative;
    width: 100%;
    height: 600px;
    background-color: #008060;
    color: #ececec;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.wave {
    padding: 0px !important;
    margin: 0px !important;
    width: 100%;
    position: relative;
    top: -2px;
    color: #008060;
}

.splash-button {
    background-color: black;
    font-size: 13px;
    font-weight: 700;
    color: white;
    width: 250px;
    height: 50px;
    margin: 5px;
    border: none;
    border-radius: 50px;
    transition: box-shadow 0.2s;
    letter-spacing: 1.5px;
}

.splash-button:hover,
.splash-button:active {
    -webkit-box-shadow: 0px 0px 10px 1px #000000;
    box-shadow: 0px 0px 10px 1px #000000;
    transition: box-shadow 0.2s;
}

.overview {
    padding-top: 50px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.overview-icon {
    height: 13%;
}

.info-block {
    width: 300px;
    height: 200px;
}

@media screen and (max-width: 900px) {
    .overview {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.splash-title {
    margin: 0px;
}

.splash-sub-title {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
}

.splash-content {
    position: relative;
    bottom: -50px;
}

@media screen and (max-width: 900px) {
    .splash-content {
        bottom: -30px;
    }
}
